@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");

:root {
  --font-base: "DM Sans", sans-serif;

  --primary-color: #0f0f10;
  --secondary-color: #039f35;
  --black-color: #e9e9e9;
  --lightGray-color: #252424;
  --gray-color: #039f35;
  --brown-color: #46364a;
  --white-color: #000000;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}
